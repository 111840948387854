import React from 'react'
import Card from '../Card'
import { Box, Typography, useTheme } from '@mui/material'
import { useStyles } from './styles'
import BlueWaterIcon from 'assets/water-blue.svg';
import GreenWaterIcon from 'assets/water-green.svg';
import { VictoryPie } from 'victory';
import { useTranslation } from 'react-i18next';


export default function RingChart({
    type="DAILY",
    percentage=0
}:{
    type?: "DAILY" | "MONTHLY"
    percentage?: number
    average?: number
}) {
    const classes = useStyles()
    const computedPercentage = percentage > 100 ? percentage - 100 : percentage
    const data = [
        { x: 1, y: computedPercentage },
        { x: 2, y: 100 - computedPercentage }
    ]
    const { t } = useTranslation()
    const theme = useTheme();
    const dailyTrackColor = percentage <= 100 ? ['#78BEFF', '#c7dffe'] : ['#4596FF', '#78BEFF']
    const monthlyTrackColor = percentage <= 100 ? ['#58BC7C', '#CCEAD7'] : ['#449461', '#58BC7C']

    return (
        <Card
            style={{
                padding: "34px 30px",
                gap: "16px",
                display: "flex",
                flexDirection: "column"
            }}
        >
            <Box
                className={classes.row}
                sx={{
                    direction: theme.direction
                }}
            >
                <Typography style={type === "DAILY" ? {color:"#4596FF"} : {color:"#58BC7C"}} className={classes.title}>{type === "DAILY" ? t('DASHBOARD.DAILY_READING') : t('DASHBOARD.MONTHLY_READING')}</Typography>
                <Box>
                    <img src={type === "DAILY" ? BlueWaterIcon : GreenWaterIcon} alt="water_icon" />
                </Box>
            </Box>
            <Box
                sx={{
                    position: "relative"
                }}
            >
                <svg viewBox="0 0 100 100">
                    <VictoryPie
                        standalone={false}
                        width={100}
                        height={100}
                        data={data}
                        innerRadius={50}
                        colorScale={type === "DAILY" ? dailyTrackColor : monthlyTrackColor}
                        padding={10}
                        labels={({ datum }) => null}
                    />
                </svg>
                <Box
                    sx={{
                        position: "absolute",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        top: 0,
                        bottom: 0,
                        width: "100%"
                    }}
                >
                    <Typography
                        sx={{
                            color: type === "DAILY" ? "#4596FF" : "#58BC7C",
                            fontFamily: "Roboto",
                            fontSize: "72px",
                            fontWeight: 700,
                            lineHeight: "26.4px",
                        }}
                    >
                        {
                            percentage > 0 ?
                            percentage?.toFixed(1) :
                            percentage?.toFixed()
                        }%
                    </Typography>
                </Box>
            </Box>
        </Card>
    )
}
