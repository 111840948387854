import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        padding: "38px 60px 68px"
    },
    contentWrapper: {
        width: "100%",
        height: "441px",
        padding: "16px 35px",
        borderRadius: "16px",
        background: "rgba(130, 188, 238, 0.10)",
        boxShadow: "0px 4px 10px 0px rgba(41, 45, 50, 0.04)",
        marginBottom: "26px",
        overflow: "auto",
        display: "flex",
        flexDirection: "column"
    },
    label: {
        color: "#161616",
        fontFamily: "Roboto",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "normal",
    },
    loadingText: {
        color: "#161616",
        fontFamily: "Roboto",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "normal",
        textAlign: "center",
    },
    btnText: {
      color: "#FFF",
      textAlign: "center",
      fontFamily: "Roboto",
      fontSize: "18px",
      fontWeight: 400,
    },
    btnContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    row: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: "20.5px",
        marginBottom: "20.5px"
    },
    title: {
        color: "#161616",
        fontFamily: "Roboto",
        fontSize: "24px",
        fontWeight: 700,
    }
}));
