import { Box, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Dropdown from '../Dropdown'
import Card from '../Card'
import DailyBarChart from '../DailyBarChart'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { actions } from 'app/containers/Usage/slice'
import storage from 'utils/storage'
import { KEYS_OF_FILTER_OPTION } from 'app/containers/Usage/types'
import { selectDailyBarGraphData, selectDailyMean } from 'app/containers/Usage/selector'
import { useSelector } from 'react-redux'

export default function DailyGraph() {
    const {t} = useTranslation();
    const [selected, setSelected] = useState<KEYS_OF_FILTER_OPTION>('SEVEN_DAYS')
    const dispatch = useDispatch();
    const data = useSelector(selectDailyBarGraphData);
    const mean  = useSelector(selectDailyMean)
    const theme = useTheme();

    const DAILY_LIST = [
        {
            label: `${t('USAGE.DAILY.OPTIONS.SEVEN_DAYS')}`,
            value: "SEVEN_DAYS"
        },
        {
            label: `${t('USAGE.DAILY.OPTIONS.START_MONTH')}`,
            value: "START_MONTH"
        },
        {
            label: `${t('USAGE.DAILY.OPTIONS.THIRTY_DAYS')}`,
            value: "THIRTY_DAYS"
        }
    ]

    useEffect(() => {
        storage.get('currentProfileId').then((id) => {
            if(id){
                dispatch(
                    actions.getDailyBarGraphData({
                        id,
                        type: 'DAILY',
                        option: selected,
                    }),
                );
            }
        })
    }, [selected, dispatch]);


    return (
        <Box
            sx={{
                direction: theme.direction
            }}
        >
            <Dropdown options={DAILY_LIST} value={selected} handleChange={(e)=>setSelected(e?.target.value)} />
            <Card
                style={{
                    height: "277px",
                    borderRadius: "30px",
                    border: "2.973px solid #EFF0F6",
                    background: "rgba(254, 254, 254, 0.80)",
                    marginTop: "16px",
                    marginBottom: "56px",
                    padding: "16px",
                }}
            >
                <DailyBarChart
                    data={data}
                    mean={mean}
                />
            </Card>
        </Box>
    )
}
