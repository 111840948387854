import React, { ChangeEvent, useState } from 'react'
import Card from '../Card'
import { Box, Button, Typography } from '@mui/material'
import IOSSwitch from '../Switch'
import icon from 'assets/infoIcon.svg'
import { useTranslation } from 'react-i18next'
import NotificationTip from '../NotificationTip'

interface Props {
    subText?: string;
    title?: string;
    isControlled?: boolean;
    showInfoIcon?: boolean;
    onActivate?: (e: ChangeEvent) => void
    isEnable?: boolean
    toggleSMS?: (e: ChangeEvent) => void
    toggleEmail?: (e: ChangeEvent) => void
    togglePush?: (e: ChangeEvent) => void
    isSMS?: boolean
    isEmail?: boolean
    isPush?: boolean
    emailDisabled?: boolean
}

export default function NotificationCard({
    title,
    subText,
    isControlled=false,
    showInfoIcon=true,
    onActivate,
    isEnable=false,
    toggleSMS,
    toggleEmail,
    togglePush,
    isSMS=false,
    isEmail=false,
    isPush=false,
    emailDisabled=false,
}:Props) {
    const { t } = useTranslation()
    const [showTip, setShowTip] = useState(false)


    return (
        <Card
            style={{
                borderRadius: "16px",
                border: "1px solid #E9E9EA",
                background: "#FFF"
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "15px 16px",
                    borderBottom: "1px solid #E9E9EA"
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "4px"
                    }}
                >
                    {
                        showInfoIcon &&
                        <Button
                            sx={{
                                minWidth: 0,
                                padding: 0
                            }}
                            onClick={()=>setShowTip(true)}
                        >
                            <img alt="info" src={icon} />
                        </Button>
                    }
                    <Box>
                        <Typography
                            sx={{
                                color: "#161616",
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                fontWeight: 600,
                            }}
                        >
                            {title}
                        </Typography>
                        {
                            subText && isEnable &&
                            <Typography
                                sx={{
                                    color: "#999",
                                    fontFamily: "Roboto",
                                    fontSize: "12px",
                                    fontWeight: 400,
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap"
                                }}
                            >
                                {subText}
                            </Typography>
                        }
                    </Box>
                </Box>
                {
                    isControlled &&
                    <IOSSwitch
                        value={isEnable}
                        checked={isEnable}
                        onChange={onActivate}
                    />
                }
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "15px 16px",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "8px",
                        maxWidth: "32%"
                    }}
                >
                    <Typography
                        sx={{
                            color: !isEnable ? "#999" : "#000",
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            fontWeight: 600,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap"
                        }}
                    >
                        {t("AUTH.EMAIL")}
                    </Typography>
                    <IOSSwitch
                        value={isEmail}
                        checked={isEmail}
                        onChange={toggleEmail}
                        disabled={!isEnable||emailDisabled}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "8px",
                        maxWidth: "32%"
                    }}
                >
                    <Typography
                        sx={{
                            color: !isEnable ? "#999" : "#000",
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            fontWeight: 600,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap"
                        }}
                    >
                        {t("SETTINGS.SMS")}
                    </Typography>
                    <IOSSwitch
                        value={isSMS}
                        checked={isSMS}
                        onChange={toggleSMS}
                        disabled={!isEnable}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "8px",
                        maxWidth: "32%"
                    }}
                >
                    <Typography
                        sx={{
                            color: !isEnable ? "#999" : "#000",
                            fontFamily: "Roboto",
                            fontSize: "16px",
                            fontWeight: 600,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap"

                        }}
                    >
                        {t("SETTINGS.PUSH")}
                    </Typography>
                    <IOSSwitch
                        value={isPush}
                        checked={isPush}
                        onChange={togglePush}
                        disabled={!isEnable}
                    />
                </Box>
            </Box>
            <NotificationTip
                open={showTip}
                handleClose={()=>setShowTip(false)}
            />
        </Card>
    )
}
