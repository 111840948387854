import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import TextInput from "app/components/TextInput";
import CustomButton from "app/components/Button";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLgStyles } from "./styles";
import { actions } from "app/containers/Auth/slice";
import { selectSettingConfig, selectLoading } from "app/containers/Auth/selectors";
import { onValidatePhoneNumber } from "app/containers/Auth/validator";

export default function SignupForm() {
    const classes = useLgStyles();
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const images = useSelector(selectSettingConfig)
    const isLoading = useSelector(selectLoading)
    const [payerId, setPayerId] = useState("")
    const [waterId, setWaterId] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [errors, setErrors] = useState({
        payerId: "",
        waterId: "",
        phoneNumber: ""
    })

    const onSubmit = async (e)=> {
        e.preventDefault()
        const phoneNumberErrors = onValidatePhoneNumber(phoneNumber)
        const payerIdErrors = payerId?.length > 25 ? "AUTH.ENTER_VALID_BILL_NUMBER" : ""
        const waterIdErrors = waterId?.length > 25 ? "AUTH.ENTER_VALID_WATER_ID" : ""
        if(phoneNumberErrors || payerIdErrors || waterIdErrors){
            setErrors({
                ...errors,
                phoneNumber: phoneNumberErrors??"",
                payerId: payerIdErrors,
                waterId: waterIdErrors
            })
            return
        }
        dispatch(actions.setRegisterForm({
            payerId: payerId,
            waterId: waterId,
            phoneNumber: phoneNumber
        }))
    }

    return (
        <form
            style={{
                minWidth: "454px"
            }}
        >
            <Typography
                className={classes.title}
            >
            {t("AUTH.CREATE_AN_ACCOUNT")}
            </Typography>
            <TextInput
                label={t("AUTH.PAYER_NUMBER")}
                placeholder="0000000000"
                containerStyle={{
                    marginBottom: "24px"
                }}
                onChange={(e)=>{
                    if(e?.target?.value?.length < 26){
                        setErrors({
                            ...errors,
                            payerId: ""
                        })
                        setPayerId(e?.target?.value)
                    }
                }}
                value={payerId}
                error={errors?.payerId !== "" ? t(errors?.payerId) : ""}
                infoSrc={images?.payerIdImage}
            />
            <TextInput
                label={t("AUTH.WATER_ID")}
                placeholder="0000000000"
                containerStyle={{
                    marginBottom: "24px"
                }}
                onChange={(e)=>{
                    if(e?.target?.value?.length < 26){
                        setErrors({
                            ...errors,
                            waterId: ""
                        })
                        setWaterId(e?.target?.value)
                    }
                }}
                value={waterId}
                error={errors?.waterId !== "" ? t(errors?.waterId) : ""}
                infoSrc={images?.waterIdImage}
            />
            <TextInput
                label={t("AUTH.MOBILE_NUMBER")}
                placeholder="0000000000"
                containerStyle={{
                    marginBottom: "24px"
                }}
                onChange={(e)=>{
                    if(isNaN(e?.target?.value))return
                    if(e?.target?.value?.length < 11){
                        setErrors({
                            ...errors,
                            phoneNumber: ""
                        })
                        setPhoneNumber(e?.target?.value)
                    }
                }}
                value={phoneNumber}
                error={errors?.phoneNumber !== "" ? t(errors?.phoneNumber) : ""}
            />
            <CustomButton
                onClick={onSubmit}
                btnStyle={{
                    marginTop: "32px"
                }}
                type="submit"
                disabled={isLoading || payerId === "" || waterId === "" || phoneNumber?.length < 10}
                isLoading={isLoading}
            >
                <Typography className={classes.btnText}>{t("BUTTONS.NEXT")}</Typography>
            </CustomButton>
        </form>
    )
}
