import React, {memo} from 'react';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryTheme,
  VictoryZoomContainer,
} from 'victory';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import { selectIsDailyLoading } from 'app/containers/Usage/selector';
import { Box, CircularProgress } from '@mui/material';

type Props = {
  data: any;
  mean: any;
};

const UsageDailyGraph = ({
  data,
  mean
}:Props) => {
  const isLoading = useSelector(selectIsDailyLoading);
  const chatHasData =
    !isLoading &&
    data.length > 0 &&
    data.some(c => c.consumption > 0);
  const {t} = useTranslation();
  const isPanEnabled = data.length > 7;
  if(isLoading){
    return (
        <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
        >
            <CircularProgress
                size={40}
                color="primary"
            />
        </Box>
    )
  }
  return (
    <VictoryChart
      width={1200}
      domainPadding={{x: 50, y: 0}}
      animate={{duration: 1000, easing: 'linear'}}
      theme={VictoryTheme.material}
      containerComponent={
        <VictoryZoomContainer
          style={{cursor: 'ew-resize', position:"relative"}}
          zoomDimension={'x'}
          allowZoom={false}
          allowPan={isPanEnabled}
          zoomDomain={{x: [0.5, 7.5]}}
        />
      }
      padding={{top: 16, bottom: 50, right: 20, left: 40}}>
      <VictoryAxis
        dependentAxis
        tickFormat={tick => {
          return tick > 0.1 ? `${tick}` : '';
        }}
        style={{
          tickLabels: {
            fill: "#78BEFF", //CHANGE COLOR OF X-AXIS LABELS
          },
          axis: {
            stroke: "#E3E3E3", //CHANGE COLOR OF X-AXIS
            strokeWidth: 1,
          },
        }}
        tickLabelComponent={(
          <VictoryLabel
            verticalAnchor="middle"
            textAnchor="middle"
            x={20}
            style={{
                fontFamily: "Roboto",
                fill: "#78BEFF",
                fontSize: 16,
            }}
          />
        )}
      />
      <VictoryAxis
        data={data}
        tickFormat={(tick, index) => {
          if (String(tick).includes('Invalid')) {
            return '';
          }
          return tick;
        }}
        style={{
          tickLabels: {
            fill: "#78BEFF", //CHANGE COLOR OF X-AXIS LABELS,
            angle: -20,
            padding: 15,
          },
          axis: {
            stroke: "#E3E3E3", //CHANGE COLOR OF X-AXIS
            strokeWidth: 1,
          },
        }}
        fixLabelOverlap={false}
        tickLabelComponent={
          <VictoryLabel
            style={{
              fontFamily: "Roboto",
              fill: "#78BEFF",
              fontSize: 20,
            }}
          />
        }
      />
      {!isLoading && !chatHasData ? (
        <VictoryLabel
          text={t('CHARTS.NO_DATA')}
          x={200}
          y={250}
          style={{fontSize: 34, fontWeight: '600', fill: '#DFDFDF'}}
          textAnchor="middle"
        />
      ) : null}

      <VictoryBar
        alignment="middle"
        data={data}
        barWidth={75}
        barRatio={0.5}
        x="groupName"
        y="consumption"
        style={{
          data: {
            fill: ({datum}) => {
              return datum.isCurrent
                ? "#4596FF"
                : "#78BEFF";
            },
            stroke: 10,
          },
        }}
      />
      <VictoryLine
        data={
          chatHasData
            ? [
                {
                  date: 0,
                  consumption: mean,
                },
                {
                  date: data.length + 1,
                  consumption: mean,
                },
              ]
            : []
        }
        style={{
          data: {stroke: "#0491FF", strokeWidth: 3},
        }}
        x="date"
        y="consumption"
      />
    </VictoryChart>
  );
};

export default memo(UsageDailyGraph);
