/**
 * Gets the repositories of the user from Player
 */

import {call, put, takeLatest} from 'redux-saga/effects';
import {
  API_URL,
  OTP_SENT
} from 'utils/constants';
import {request} from 'utils/request';

import {LOGIN_SUCCESSFULLY} from 'utils/constants';
import {ENG_TO_HE} from 'utils/helpers';
import storage from 'utils/storage';
import {SettingConfig, UserResponse} from '../types';
import {actions} from './slice';
import { LoggerClass } from 'utils/logger';
import { toast } from 'react-toastify';
import { LoginErrorType } from './types';
// import {actions as profileActions} from 'features/Settings/redux/settingsSlice';

/**
 * Player repos request/response handler
 */

export function* signUpRequest({payload}: any) {
  try {
    const options = {
      method: 'POST',
      body: JSON.stringify(payload),
    };
    const url = `${API_URL}auth/register`;

    const response: UserResponse = yield call(request, url, options);
    if (response && response.message && response.message === OTP_SENT)
      yield put(actions.setAuthRequestSuccess(true))
    else
      toast(ENG_TO_HE(response.message))
  } catch (err: any) {
    LoggerClass.log('=======', err.message);
    let errorData = JSON.parse(err.message);
    if (errorData.message) {
      toast(ENG_TO_HE(`COMMON.${errorData.message}`))
      yield put(actions.setAuthRequestSuccess(false))
    }
  }
}


export function* getMeApi({payload}: any) {
  try {
    const options = {
      method: 'GET',
    };
    const url = `${API_URL}auth/me`;
    const response: UserResponse = yield call(request, url, options);
    const currentProfileId: string = yield storage.get('currentProfileId');
    if (!currentProfileId) {
      storage.set('currentProfileId', response.userProfiles[0].id);
    }
    yield put(actions.setUser(response));
  } catch (err: any) {
    LoggerClass.log('=======', err.message);
    let errorData = JSON.parse(err.message);
    if (errorData.message) {
      if(errorData?.name === "AuthorizationRequiredError"){
        yield call(payload?.callback)
        yield put(actions.setIsLoading(false));
        toast(ENG_TO_HE(`COMMON.SESSION_EXPIRED`))
        return;
      }
      if(errorData?.message === "USER.ERRORS.INACTIVE"){
        yield call(payload?.callback)
        yield put(actions.setIsLoading(false));
        toast(ENG_TO_HE(`COMMON.USER_INACTIVE`))
        return;
      }
      toast(ENG_TO_HE(`COMMON.${errorData.message}`))
      yield put(actions.setAuthError(errorData.message));
    }
  }
}

export function* verifyRegisterOtp({payload}: any) {
  try {
    const options = {
      method: 'POST',
      body: JSON.stringify(payload),
    };
    const url = `${API_URL}auth/register`;

    const response: UserResponse = yield call(request, url, options);
    if (response && response.message === LOGIN_SUCCESSFULLY) {
      storage.set('sessionToken', response.token);
      const currentProfileId = response.userProfiles.find(u=>u?.appMeasureEntity?.accountSecondaryId === payload.waterId)?.id;
      if(currentProfileId){
        storage.set('currentProfileId', currentProfileId);
      } else {
        storage.set('currentProfileId', response.userProfiles[0].id);
      }
      if (response.viewedTermsCondition) {
        yield put(actions.setInitialPage("/"))
      }
      else {
        yield put(actions.setInitialPage("/auth/all-set"))
      }
      return
    }
    throw new Error(response?.message)
  } catch (err: any) {
    LoggerClass.log('=======', err.message);
    // toast(ENG_TO_HE(`COMMON.${err.message}`))
    yield put(actions.setAuthError(err.message));
  }
}

export function* acceptTermsConditionRequest({payload}: any) {
  try {
    const options = {
      method: 'POST',
      body: JSON.stringify(payload),
    };
    const url = `${API_URL}users/terms-and-condition`;

    const response: boolean = yield call(request, url, options);
    if (response) {
      // storage.set('terms', 'done');
      // Navigate to dashboard page
      yield put(actions.getUser({callback: () => {}}));
      yield put(actions.setAuthRequestSuccess(true))
    }
  } catch (err: any) {
    LoggerClass.log('=======', err.message);
    let errorData = JSON.parse(err.message);
    if (errorData.message) {
      toast(ENG_TO_HE(`COMMON.${errorData.message}`))
      yield put(actions.setAuthRequestSuccess(false));
    }
  }
}

export function* getSettingConfigRequest() {
  try {
    const options = {
      method: 'GET',
    };
    const url = `${API_URL}settings/config`;
    const response: SettingConfig = yield call(request, url, options);

    yield put(actions.setSettingConfig(response));
  } catch (err: any) {
    LoggerClass.log('=======', err.message);
    let errorData = JSON.parse(err.message);
    if (errorData.message) {
      toast(ENG_TO_HE(`COMMON.${errorData.message}`))
    }
    yield put(actions.setAuthError(err.message));
  }
}
export function* loginRequest({payload}: any) {
  try {
    const options = {
      method: 'POST',
      body: JSON.stringify(payload),
    };
    const url = `${API_URL}auth/code-login`;

    const response: UserResponse = yield call(request, url, options);
    if (response && response.message && response.message === OTP_SENT){
      yield put(actions.setAuthRequestSuccess(true));
      return
    }
    throw new Error(response?.message)
  } catch (err: any) {
    LoggerClass.log('=======', err.message);
    let errorData = JSON.parse(err.message);
    if (errorData.message) {
      yield put(actions.setAuthError(errorData.message));
    }
  }
}

export function* verifyLoginOtp({payload}: any) {
  try {
    const options = {
      method: 'POST',
      body: JSON.stringify(payload),
    };
    const url = `${API_URL}auth/code-login`;

    const response: UserResponse = yield call(request, url, options);
    if (response && response.message === 'MESSAGES.USER.LOGIN_SUCCESS') {
      storage.set('sessionToken', response.token);
      const currentProfileId = response.userProfiles.find(u=>u?.appMeasureEntity?.accountSecondaryId === payload.waterId)?.id;
      if(currentProfileId){
        storage.set('currentProfileId', currentProfileId);
      } else {
        storage.set('currentProfileId', response.userProfiles[0].id);
      }
      if (response.viewedTermsCondition) {
        yield put(actions.setInitialPage("/"))
      }
      else {
        yield put(actions.setInitialPage("/auth/all-set"))
      }
      return
    }
    if (response && response.message === 'MESSAGES.AUTH.INVALID_OTP') {
      yield put(actions.setAuthError(LoginErrorType.INVALID_OTP));
      return
    }
    throw new Error(response?.message)
  } catch (err: any) {
    LoggerClass.log('=======', err.message);
    // toast(ENG_TO_HE(`COMMON.${err.message}`))
    if(err.message === "LOGIN_FAILED_INACTIVE"){
      toast(ENG_TO_HE(`COMMON.${err.message}`))
    }
    yield put(actions.setAuthError(err.message));
  }
}
export function* setCurrentProfile({payload}: any) {
  yield storage.set('currentProfileId', payload);
}

/**
 * Root saga manages watcher lifecycle
 */
export function* userAuthSaga() {
  // Watches for LOAD_REPOS actions and calls loginResponse when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount

  // yield takeLatest(actions.setCurrentProfile.type, setCurrentProfile);
  yield takeLatest(actions.setRegisterForm.type, signUpRequest);
  yield takeLatest(actions.getUser.type, getMeApi);
  yield takeLatest(actions.setRegisterOTP.type, verifyRegisterOtp);
  yield takeLatest(actions.checkAgreedToTerms.type, acceptTermsConditionRequest);
  yield takeLatest(actions.getSettingConfig.type, getSettingConfigRequest);
  yield takeLatest(actions.setLoginOTP.type, verifyLoginOtp);
  yield takeLatest(actions.emailLogin.type, loginRequest);
  yield takeLatest(actions.phoneNumberLogin.type, loginRequest);
  // yield takeLatest(profileActions.updateProfileDataSuccess?.type, getMeApi);
}
