import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useLgStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100vh",
    width: "100%",
    position: "relative",
  },
  grid: {
    height: "100%",
    padding: "27px 20px",
    overflow: "hidden"
  },
  imgWrapper: {
    borderRadius: "40px",
    backgroundColor: "#D6CCC5",
    height: "100%",
    overflow: "hidden",
    position: "relative"
  },
  contentWrapper: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },
  title: {
    textAlign: "center",
    fontFamily: "Roboto",
    fontSize: "40px",
    fontWeight: 600,
    marginBottom: "41px"
  },
  link: {
      color: "#4596FF",
      textAlign: "center",
      fontFamily: "Roboto",
      fontSize: "18px",
      fontWeight: 700,
      lineHeight: "24px",
      margin: "24px 0",
      cursor: "pointer"
  },
  btnText: {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: 400,
  },
  blueGradiant: {
    width: "616px",
    height: "689px",
    flexShrink: 0,
    borderRadius: "689px",
    background: "#486ECD",
    filter: "blur(100px)",
    position: "absolute",
    top: "441px",
    left: "284px",
    zIndex: 1
  },
  greenGradiant: {
    width: "355px",
    height: "378px",
    flexShrink: 0,
    borderRadius: "378px",
    background: "#58BC7C",
    filter: "blur(100px)",
    position: "absolute",
    top: "688px",
    left: "167px",
    zIndex: 2
  },
  purpleGradiant: {
    width: "473px",
    height: "651px",
    flexShrink: 0,
    borderRadius: "651px",
    background: "rgba(114, 100, 228, 0.60)",
    filter: "blur(100px)",
    position: "absolute",
    top: "400px",
    left: "-171px",
  },
  phone: {
    width: "300px",
    height: "607.018px",
    flexShrink: 0,
    position: "absolute",
    top: "150px",
    left: "150px",
    zIndex: 3
  },
  cards: {
    width: "237px",
    height: "419.83px",
    top: "600px",
    left: "403px",
    position: "absolute",
    zIndex: 2
  },
  btnText2: {
    color: "#FFF",
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: 700,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    paddingBlock: "20px",
    alignItems: "center",
    justifyContent: "space-between"
  },
  resend: {
    color: "#4596FF",
    textDecoration: "none",
    textAlign: "center",
    cursor: "pointer"
  },
  supportText: {
    color: "#9B9B9B",
    fontSize: "16px",
    lineHeight: "18px"
  },
  supportImg: {
    width: "35px",
    height: "35px",
    cursor: "pointer"
  },
  error: {
    color: "#BA0404",
    textAlign: "center",
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "24px",
  }
}));
