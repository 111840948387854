import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TextInput from "app/components/TextInput";
import CustomButton from "app/components/Button";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useLgStyles } from "./styles";
import { selectError, selectLoading } from "app/containers/Auth/selectors";
import { onValidateEmail, onValidatePhoneNumber } from "app/containers/Auth/validator";
import { actions } from "app/containers/Auth/slice";


export default function LoginForm({
  loginForm,
  setLoginForm
}:{
  loginForm: "PHONE" | "EMAIL"
  setLoginForm: React.Dispatch<React.SetStateAction<"PHONE" | "EMAIL">>
}) {
    const classes = useLgStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation()
    const isLoading = useSelector(selectLoading)
    const errorMessage = useSelector(selectError)
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [errors, setErrors] = useState("")

    useEffect(()=>{
      if(errorMessage){
        setErrors(t(`COMMON.${errorMessage}`))
      }
    },[errorMessage])

    const toggleForm = ()=> {
      setLoginForm(prevState=>{
        if(prevState === "PHONE"){
          return "EMAIL"
        }
        return "PHONE"
      })
      setErrors("")
    }

    const onSubmit = async () => {
      if(loginForm === "EMAIL"){
        const error = onValidateEmail(email)
        if(error){
          setErrors(t(error))
          return
        }
        dispatch(actions.emailLogin({
          email:email
        }))
        return
      }
      if(loginForm === "PHONE"){
        const error = await onValidatePhoneNumber(phoneNumber)
        if(error){
          setErrors(t(error))
          return
        }
        dispatch(actions.phoneNumberLogin({
          phoneNumber:phoneNumber
        }))
        return
      }
    }

    return (
      <Box
          style={{
              minWidth: "454px"
          }}
      >
          <Typography
              className={classes.title}
          >
          {
              loginForm === "PHONE" ?
              t("AUTH.LOG_BY_PHONE_NUMBER") :
              t("AUTH.LOG_BY_EMAIL")
          }
          </Typography>
          <TextInput
              type={loginForm === "EMAIL" ? "email" : "text"}
              label={loginForm === "PHONE" ? t("AUTH.MOBILE_NUMBER") : t("AUTH.EMAIL")}
              placeholder={loginForm === "PHONE" ? "0000000000" : t("AUTH.EMAIL")}
              onChange={
                loginForm === "EMAIL" ?
                (e)=>{
                  setErrors("")
                  setEmail(e?.target?.value)
                } :
                (e)=>{
                  const value = e.target.value
                  if(isNaN(value))return
                  if(value.length < 11){
                    setErrors("")
                    setPhoneNumber(value)
                  }
                }
              }
              disabled={isLoading}
              value={loginForm === "EMAIL" ? email : phoneNumber}
              error={errors}
              onKeyPress={(e)=>{
                if(e.key === "Enter"){
                  onSubmit()
                }
              }}
          />
          <Box justifyContent="center" alignItems="center" display="flex">
          <Typography
              onClick={toggleForm}
              component="a"
              className={classes.link}
          >
              {
                  loginForm === "PHONE" ?
                  t("AUTH.LOG_BY_EMAIL") :
                  t("AUTH.LOG_BY_PHONE_NUMBER")
              }
          </Typography>
          </Box>
          <CustomButton
              onClick={onSubmit}
              btnStyle={{
                  marginTop: "32px"
              }}
              isLoading={isLoading}
              disabled={(loginForm === "EMAIL" ? email === "" : phoneNumber?.length < 10) || isLoading}
          >
              <Typography className={classes.btnText}>{t("BUTTONS.NEXT")}</Typography>
          </CustomButton>
          <Typography
            sx={{
              marginTop: "32px",
              textAlign: "center",
              color: "#9CA2B0",
            }}
          >
            {t("AUTH.VERSION", { version: process.env.REACT_APP_VERSION })}
          </Typography>
      </Box>
    )
}
