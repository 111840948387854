import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import { dateFormat } from 'utils/constants';
import {
    VictoryAxis, VictoryBar, VictoryChart, VictoryLine, VictoryTheme, VictoryZoomContainer, VictoryLabel
} from 'victory';

type Props = {
  barGraphInfo: any;
  isDays: boolean;
  yesterdayData: any;
  averageConsumption: any;
  isLoading?: boolean;
};

const DayBarChart = ({
  barGraphInfo,
  isDays,
  yesterdayData,
  averageConsumption,
  isLoading,
}: Props) => {
  if(isLoading){
    return (
        <Box
            sx={{
              width: "100%",
              minHeight: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
        >
            <CircularProgress
                size={40}
                color="primary"
            />
        </Box>
    )
  }
  return (
    <>
      {barGraphInfo &&
        barGraphInfo.length > 0 &&
        yesterdayData &&
        isDays &&
        Object.keys(yesterdayData).length > 0 && (
          <VictoryChart
            height={250}
            domainPadding={{x: 10, y: 0}}
            theme={VictoryTheme.material}
            // animate={{duration: 1000, easing: 'linear'}}
            containerComponent={
              <VictoryZoomContainer
                style={{cursor: 'ew-resize'}}
                zoomDimension={'x'}
                allowZoom={false}
                allowPan={true}
                zoomDomain={{x: [0, 9]}}
              />
            }
            padding={{top: 16, bottom: 50, right: 40, left: 40}}>
            <VictoryAxis
              dependentAxis
              tickFormat={tick => {
                return tick > 0.1 ? `${tick}` : '';
              }}
              style={{
                tickLabels: {
                  fill: "#78BEFF", //CHANGE COLOR OF X-AXIS LABELS
                },
                axis: {
                  stroke: "#E3E3E3", //CHANGE COLOR OF X-AXIS
                  strokeWidth: 1,
                },
              }}
              tickLabelComponent={(
                <VictoryLabel
                  verticalAnchor="middle"
                  textAnchor="middle"
                  x={20}
                  style={{
                    fontFamily: "Roboto",
                    fill: "#78BEFF",
                    fontSize: 10,
                  }}
                />
              )}
            />
            <VictoryAxis
              data={barGraphInfo}
              tickCount={6}
              tickFormat={(tick, index) => {
                return `${dateFormat(tick, 'DD.MM')}`;
              }}
              style={{
                tickLabels: {
                  fill: "#78BEFF", //CHANGE COLOR OF X-AXIS LABELS,
                  angle: -45,
                  padding: 15,
                },
                axis: {
                  stroke: "#E3E3E3", //CHANGE COLOR OF X-AXIS
                  strokeWidth: 1,
                },
              }}
              fixLabelOverlap={false}
              tickLabelComponent={(
                <VictoryLabel
                  verticalAnchor="middle"
                  textAnchor="middle"
                  style={{
                    fontFamily: "Roboto",
                    fill: "#78BEFF",
                    fontSize: 10,
                  }}
                />
              )}
            />
            <VictoryBar
              alignment="middle"
              barRatio={0.5}
              data={barGraphInfo}
              x="layerDate"
              y="consumption"
              style={{
                data: {
                  fill: ({datum}) =>
                    datum.layerDate === yesterdayData.layerDate
                      ? "#4596FF"
                      : "#78BEFF",
                  stroke: "#4596FF",
                },
              }}
              barWidth={10}
            />
            <VictoryLine
              data={[
                {
                  date: 0,
                  consumption: averageConsumption??0,
                },
                {
                  date: barGraphInfo.length + 1,
                  consumption: averageConsumption??0,
                },
              ]}
              style={{
                data: {stroke: "#0491FF", strokeWidth: 3},
              }}
              x="date"
              y="consumption"
            />
          </VictoryChart>
        )}
    </>
  );
};

export default React.memo(DayBarChart);
