import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    title: {
        color: "rgba(22, 22, 22, 0.70)",
        fontFamily: "Roboto",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "22px",
        marginBottom: "24px"
    }
}));
