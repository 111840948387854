import { Box, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Dropdown from '../Dropdown'
import Card from '../Card'
import MonthlyBarChart from '../MonthlyBarChart'
import storage from 'utils/storage'
import { actions } from 'app/containers/Usage/slice'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { KEYS_OF_FILTER_OPTION } from 'app/containers/Usage/types'
import { selectMonthlyBarGraphData, selectMonthlyMean } from 'app/containers/Usage/selector'

export default function MonthlyGraph() {
    const {t} = useTranslation();
    const [selected, setSelected] = useState<KEYS_OF_FILTER_OPTION>('THREE_MONTHS')
    const dispatch = useDispatch();
    const data = useSelector(selectMonthlyBarGraphData);
    const mean  = useSelector(selectMonthlyMean)
    const theme = useTheme();

    const MONTHLY_LIST = [
        {
            label: `${t('USAGE.MONTHLY.OPTIONS.THREE_MONTHS')}`,
            value: "THREE_MONTHS"
        },
        {
            label: `${t('USAGE.MONTHLY.OPTIONS.FOUR_MONTHS')}`,
            value: "FOUR_MONTHS"
        },
        {
            label: `${t('USAGE.MONTHLY.OPTIONS.SIX_MONTHS')}`,
            value: "SIX_MONTHS"
        },
        {
            label: `${t('USAGE.MONTHLY.OPTIONS.FIFTEEN_MONTHS')}`,
            value: "FIFTEEN_MONTHS"
        }
    ]

    useEffect(() => {
        storage.get('currentProfileId').then((id) => {
            if(id){
                dispatch(
                    actions.getMonthlyBarGraphData({
                        id,
                        type: 'MONTHLY',
                        option: selected,
                    }),
                );
            }
        })
    }, [selected, dispatch]);
    return (
        <Box
            sx={{
                direction: theme.direction
            }}
        >
            <Dropdown options={MONTHLY_LIST} value={selected} handleChange={(e)=>setSelected(e?.target.value)} />
            <Card
                style={{
                    height: "277px",
                    borderRadius: "30px",
                    border: "2.973px solid #EFF0F6",
                    background: "rgba(254, 254, 254, 0.80)",
                    marginTop: "16px",
                    marginBottom: "56px",
                    padding: "16px",
                }}
            >
                <MonthlyBarChart
                    data={data}
                    mean={mean}
                />
            </Card>
        </Box>
    )
}
