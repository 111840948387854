import closeIcon from 'assets/ic_close.svg';
import { Form, Formik, FormikProps } from 'formik';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Box, Input, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import CustomButton from '../Button';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface NotificationExceedingProps {
    open: boolean;
    handleClose: () => void;
    onSubmit?: (values: { value: string }) => void;
}

const initialValues = {
    value: ""
}



export default function NotificationConsumption({
    open,
    handleClose,
    onSubmit
}:NotificationExceedingProps) {
    const formikRef = useRef<FormikProps<{ value: string }>>(null);
    const { t, i18n } = useTranslation();

    const validationSchema = Yup.object().shape({
        value: Yup.string().required(t('AUTH.REQUIRED'))
    })
    const onSave = (values) => {
        onSubmit?.(values)
        if (formikRef.current) {
            formikRef.current.resetForm();
        }
    }

    const onClose = () => {
        if (formikRef.current) {
            formikRef.current.resetForm();
        }
        handleClose()
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            PaperProps={{
                sx: {
                    borderRadius: "20px",
                    border: "0.5px solid #FFF",
                    background: "#FFF",
                    padding: "16px 37px",
                    minWidth: "350px",
                    direction: i18n.dir(),
                }
            }}
        >
            <Box
                sx={{
                    marginBottom:"17px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between"
                }}
            >
                <Typography
                    sx={{
                        color: "#050415",
                        fontFamily: "Roboto",
                        fontWeight: 700,
                        fontSize: "16px"
                    }}
                >
                    {t("SETTINGS.EXCEEDING_MSG")}
                </Typography>
                <Button
                    sx={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "100%",
                        display: "flex",
                        justifyContent:"center",
                        alignItems: "center",
                        backgroundColor: "rgba(69, 150, 255, 0.40)",
                        minWidth: "0px",
                        position: "absolute",
                        top: "16px",
                        right: i18n.dir() === "ltr" ? "16px" : "unset",
                        left: i18n.dir() === "rtl" ? "16px" : "unset"
                    }}
                    onClick={onClose}
                >
                    <img alt="close" src={closeIcon} width="16px" height="16px" />
                </Button>
            </Box>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSave}
                enableReinitialize
                innerRef={formikRef}
            >
                {
                    ({values, handleChange, handleSubmit, touched, errors}) => (
                        <Form>
                            <Input
                                sx={{
                                    borderRadius: "8px",
                                    border: `1px solid ${touched?.value && !!errors?.value ? "#DD0000" : "#999999"}`,
                                    background: "#FFF",
                                    height: "46px",
                                    padding: "13px 4px",
                                    color: "#161616",
                                    fontFamily: "Roboto",
                                    fontSize: "20px",
                                    fontWeight: 500,
                                }}
                                name="value"
                                disableUnderline
                                type='number'
                                onChange={handleChange}
                                value={values?.value}
                                fullWidth
                                autoFocus
                            />
                            {
                                touched?.value && !!errors?.value &&
                                <Typography
                                    sx={{
                                        color: "#DD0000",
                                        fontFamily: "Roboto",
                                        fontSize: "12px",
                                        fontWeight: 400,
                                        marginTop: "8px"
                                    }}
                                >
                                    {errors?.value}
                                </Typography>
                            }
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    marginTop: '16px',
                                }}
                            >
                                <CustomButton
                                    onClick={()=>handleSubmit()}
                                    disabled={values?.value === ""}
                                    btnStyle={{
                                        borderRadius: "25px",
                                        width: "fit-content",
                                        boxShadow: "0px 4px 10px 0px rgba(154, 166, 177, 0.30)",
                                        fontFamily: "Roboto",
                                        color: "#fff",
                                        minWidth: "100px",
                                        height: "auto"
                                    }}
                                >
                                    {t("BUTTONS.OK")}
                                </CustomButton>
                            </Box>
                        </Form>
                    )
                }
            </Formik>
        </Dialog>
    );
}