import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    container: {
        // paddingTop: "61px"
    },
    name: {
        fontFamily: "Roboto",
        fontSize: "16px",
        fontWeight: 600,
    }
}));
