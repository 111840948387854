import { selectIsUploading, selectUploadedImage } from 'app/containers/Settings/selector';
import { actions } from 'app/containers/Settings/slice';
import { ProfileData } from 'app/containers/Settings/types';
import { FormikProps } from 'formik';
import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import { Box, CircularProgress, Typography } from '@mui/material';

import { useStyles } from './styles';
import UploadImageDialog from '../UploadImageDialog';
import { toast } from 'react-toastify';

export default function ProfilePhoto(Props:FormikProps<ProfileData>) {
    const classes = useStyles()
    const { t } = useTranslation()
    const fileInputRef:RefObject<any> = useRef(null);
    const dispatch = useDispatch()
    const uploadedImage = useSelector(selectUploadedImage)
    const isLoading = useSelector(selectIsUploading)
    const [open, setOpen] = useState(false);

    const handleImageSelect = (e) => {
        const file = e.target.files[0];
        if (!file?.type?.includes('image')) {
            toast(`${t("SETTINGS.ERRORS.INVALID_IMAGE")}`)
            return;
        }
        if (file) {
            dispatch(actions.uploadImage(file))
        }
    };

    const handleClick = () => {
        if(isLoading) return
        fileInputRef?.current?.click?.();
    };

    const onRemoveImage = () => {
        Props.setFieldValue("image", null)
        setOpen(false)
    }

    const memoizedUploadedImage = useMemo(()=>{
        return uploadedImage
    },[uploadedImage])

    useEffect(()=>{
        if(!memoizedUploadedImage) return
        Props.setFieldValue("image", memoizedUploadedImage)
    },[memoizedUploadedImage])

    const { values } = Props

    return (
        <Box
            className={classes.container}
        >

            <input
                type="file"
                accept=".jpeg, .jpg, .png"
                style={{ display: 'none' }}
                ref={fileInputRef}
                onChange={handleImageSelect}
            />
            <Box
                className={classes.imgWrapper}
                // onClick={values?.image?.url||values.image?.thumb ? ()=>setOpen(true) : handleClick}
            >
                {
                    isLoading ?
                    <CircularProgress
                        size={20}
                        color="secondary"
                    /> :
                    values?.image?.url||values.image?.thumb ?
                    <img
                        src={values.image?.thumb ? `${process.env.REACT_APP_API_URL?.replace('/api', '')}${values.image?.thumb}` : values.image?.url}
                        alt="profile"
                        width={"100%"}
                        height={"100%"}
                        style={{
                            borderRadius: "50%"
                        }}
                    /> :
                    <AddPhotoAlternateOutlinedIcon />
                }
            </Box>
            {/* <Typography
                className={classes.text}
                onClick={values?.image?.url||values.image?.thumb ? ()=>setOpen(true) : handleClick}
            >
                {t("SETTINGS.CHANGE_PHOTO")}
            </Typography> */}
            <UploadImageDialog
                open={open}
                onUpload={handleClick}
                onRemove={onRemoveImage}
                handleClose={()=>setOpen(false)}
            />
        </Box>
    )
}
