import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import {
    VictoryAxis, VictoryBar, VictoryChart, VictoryLine, VictoryTheme, VictoryZoomContainer, VictoryLabel
} from 'victory';

type Props = {
  monthlyBarInfo: any;
  isDays: boolean;
  yesterdayMonthData: any;
  averageMonthConsumption: any;
  isLoading?: boolean;
};

const MonthBarChart = ({
  monthlyBarInfo,
  isDays,
  yesterdayMonthData,
  averageMonthConsumption,
  isLoading,
}: Props) => {
  if(isLoading){
    return (
        <Box
            sx={{
              width: "100%",
              minHeight: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
        >
            <CircularProgress
                size={40}
                color="primary"
            />
        </Box>
    )
  }
  return (
    <>
      {monthlyBarInfo &&
        monthlyBarInfo.length > 0 &&
        yesterdayMonthData &&
        !isDays &&
        Object.keys(yesterdayMonthData).length > 0 && (
          <VictoryChart
            height={250}
            domainPadding={{x: 20, y: 0}}
            theme={VictoryTheme.material}
            // animate={{duration: 1000, easing: 'linear'}}
            // containerComponent={
            //   <VictoryZoomContainer
            //     style={{cursor: 'ew-resize'}}
            //     zoomDimension={'x'}
            //     allowZoom={false}
            //     allowPan={true}
            //     zoomDomain={{x: [0, 12]}}
            //   />
            // }
            padding={{top: 16, bottom: 50, right: 40, left: 40}}>
            <VictoryAxis
              dependentAxis
              tickFormat={tick => {
                return tick > 0.1 ? `${tick}` : '';
              }}
              style={{
                tickLabels: {
                  fill: "#abddbd", //CHANGE COLOR OF X-AXIS LABELS
                },
                axis: {
                  stroke: "#E3E3E3", //CHANGE COLOR OF X-AXIS
                  strokeWidth: 1,
                },
              }}
              tickLabelComponent={(
                <VictoryLabel
                  verticalAnchor="middle"
                  textAnchor="middle"
                  style={{
                    fontFamily: "Roboto",
                    fill: "#abddbd",
                    fontSize: 10,
                  }}
                  x={20}
                />
              )}
            />
            <VictoryAxis
              data={monthlyBarInfo}
              tickFormat={(tick, index) => {
                return tick;
              }}
              style={{
                tickLabels: {
                  fill: "#abddbd", //CHANGE COLOR OF X-AXIS LABELS,
                  angle: -45,
                  padding: 15,
                },
                axis: {
                  stroke: "#E3E3E3", //CHANGE COLOR OF X-AXIS
                  strokeWidth: 1,
                },
              }}
              tickLabelComponent={(
                <VictoryLabel
                  verticalAnchor="middle"
                  textAnchor="middle"
                  style={{
                    fontFamily: "Roboto",
                    fill: "#abddbd",
                    fontSize: 10,
                  }}
                />
              )}
            />
            <VictoryBar
              alignment='middle'
              data={monthlyBarInfo}
              // barWidth={30}
              barRatio={0.5}
              x="monthName"
              y="consumption"
              style={{
                data: {
                  fill: ({datum}) =>
                    datum.consumption ==
                    monthlyBarInfo[monthlyBarInfo.length - 1].consumption
                      ? "#58BC7C"
                      : "#abddbd",
                  stroke: "#58BC7C",
                },
              }}
            />
            <VictoryLine
              data={[
                {
                  date: 0,
                  consumption: averageMonthConsumption,
                },
                {
                  date: monthlyBarInfo.length + 1,
                  consumption: averageMonthConsumption,
                },
              ]}
              style={{
                data: {stroke:"#58BC7C", strokeWidth: 3},
              }}
              x="date"
              y="consumption"
            />
          </VictoryChart>
        )}
    </>
  );
};

export default React.memo(MonthBarChart);
